import React from 'react';
import { TrackedLink } from '../..';

import './secondary-nav.scss';

const SecondaryNav = () => {
  return (
    <nav className="secondary-nav">
      <TrackedLink type="secondary-nav__link" to="/download" children="Download" />
      <TrackedLink type="secondary-nav__link btn" to="https://app.partie.com" children="Log In" />
    </nav>
  )
}

export default SecondaryNav;
